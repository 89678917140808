<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name:
            this.backLinkName.indexOf('r_edit') !== -1
              ? 'r_resources-help-online-edit-models'
              : 'r_resources-help-online-add-models'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("resources", "resource-model", "resource-model")
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('resources', 'resource-model', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="clebex-item-section"
      v-if="listOfResourceModels && listOfResourceModels.length"
    >
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="timeZoneRadioNotDefined"
                  type="checkbox"
                  v-model="notDefined"
                />
                <label for="timeZoneRadioNotDefined"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{
                displayLabelName("resources", "resource-model", "not-defined")
              }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <ul
      class="clebex-item-section pill"
      v-if="listOfResourceModels && listOfResourceModels.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="resourceType in listOfResourceModels"
        :key="resourceType.id"
      >
        <a
          href="#"
          @click.prevent="selectResource(resourceType)"
          class="clebex-item-content-wrapper"
        >
          <span class="label">
            <span class="highlight">{{ resourceType.name }}</span>
          </span>
        </a>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "SelectResourceModel",
  mixins: [helpOnlineMixin],
  data() {
    return {
      notDefined: false,
      searchQuery: "",
      helpSlug: "resources-models"
    };
  },
  computed: {
    ...mapState("resource", [
      "resource",
      "resourceModels",
      "selectedResourceModel"
    ]),
    listOfResourceModels() {
      const query = this.searchQuery;
      if (
        this.resourceModels &&
        this.resourceModels.data &&
        this.resourceModels.data.length
      ) {
        if (query && query.length > 1) {
          return this.resourceModels.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.resourceModels.data;
        }
      }
      return null;
    }
  },
  created() {
    if (this.backLinkName.indexOf("r_edit") !== -1) {
      const { resource } = this;

      if (
        resource &&
        resource.data &&
        this.canItem(this.resource.data, "update")
      ) {
        this.getResourceModels();
      } else {
        this.$router.push({ name: "r_resources" });
      }
    } else {
      this.getResourceModels();
    }
  },
  watch: {
    notDefined() {
      if (this.notDefined) {
        this.setSelectedResourceModel(null);
      }
    }
  },
  methods: {
    ...mapActions("resource", [
      "getResourceModels",
      "setSelectedResourceModel"
    ]),
    selectResource(resource) {
      this.setSelectedResourceModel(resource);
      this.$router.push({ name: this.backLinkName });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
